import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: '18-25',
    control: 10,
    exposed: 19,
    baselineRecall: 0.5263,
    absoluteLift: 0.0165,
    lowerCI: -0.122,
    upperCI: 0.1551,
    pValue: 0.244,
    isSignificant: false,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.5263,
            confidenceInterval: [0, 0.2771],
          },
          {
            name: 'Exposed',
            value: 0.5428,
            confidenceInterval: [0, 0.2771],
          },
        ],
      },
    ],
  },
  {
    title: '25-34',
    control: 4,
    exposed: 29,
    baselineRecall: 0.1667,
    absoluteLift: 0.3512,
    lowerCI: 0.2413,
    upperCI: 0.461,
    pValue: 6.381,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.1667,
            confidenceInterval: [0, 0.2197],
          },
          {
            name: 'Exposed',
            value: 0.5179,
            confidenceInterval: [0, 0.2197],
          },
        ],
      },
    ],
  },
  {
    title: '35-44',
    control: 22,
    exposed: 42,
    baselineRecall: 0.5946,
    absoluteLift: -0.1331,
    lowerCI: -0.219,
    upperCI: -0.0471,
    pValue: -3.011,
    isSignificant: false,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.5946,
            confidenceInterval: [0, 0.1719],
          },
          {
            name: 'Exposed',
            value: 0.4615,
            confidenceInterval: [0, 0.1719],
          },
        ],
      },
    ],
  },
  {
    title: '45-54',
    control: 17,
    exposed: 48,
    baselineRecall: 0.6538,
    absoluteLift: -0.0109,
    lowerCI: -0.0965,
    upperCI: 0.0861,
    pValue: -0.109,
    isSignificant: false,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.6538,
            confidenceInterval: [0, 0.1826],
          },
          {
            name: 'Exposed',
            value: 0.6429,
            confidenceInterval: [0, 0.1826],
          },
        ],
      },
    ],
  },
  {
    title: '55-64',
    control: 10,
    exposed: 35,
    baselineRecall: 0.4545,
    absoluteLift: 0.1489,
    lowerCI: 0.0432,
    upperCI: 0.2546,
    pValue: 2.685,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.4545,
            confidenceInterval: [0, 0.2114],
          },
          {
            name: 'Exposed',
            value: 0.6034,
            confidenceInterval: [0, 0.2114],
          },
        ],
      },
    ],
  },
  {
    title: '65+',
    control: 4,
    exposed: 6,
    baselineRecall: 0.6667,
    absoluteLift: -0.1212,
    lowerCI: -0.3682,
    upperCI: 0.1258,
    pValue: -1.015,
    isSignificant: false,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.6667,
            confidenceInterval: [0, 0.494],
          },
          {
            name: 'Exposed',
            value: 0.5455,
            confidenceInterval: [0, 0.494],
          },
        ],
      },
    ],
  },
];

const columns = [
  {
    title: 'Age',
    sort: 'age',
  },
  {
    title: '# Control',
    sort: 'control',
  },
  {
    title: '# Exposed',
    sort: 'exposed',
  },
  {
    title: 'Baseline recall',
    sort: 'baselineRecall',
  },
  {
    title: 'Absolute lift',
    sort: 'absoluteLift',
  },
  {
    title: 'Lower CI',
    sort: 'lowerCI',
  },
  {
    title: 'Upper CI',
    sort: 'upperCI',
  },
  {
    title: 'P value',
    sort: 'pValue',
  },
  {
    title: 'Is significant',
    sort: 'isSignificant',
  },
];

const rows = data.map((row) => ({
  sortValues: {
    age: row.title,
    control: row.control,
    exposed: row.exposed,
    baselineRecall: row.baselineRecall,
    absoluteLift: row.absoluteLift,
    lowerCI: row.lowerCI,
    upperCI: row.upperCI,
    pValue: row.pValue,
    isSignificant: row.isSignificant,
  },
  component: (
    <>
      <td>{row.title}</td>
      <td>{row.control}</td>
      <td>{row.exposed}</td>
      <td>{(row.baselineRecall * 100).toFixed(2)}%</td>
      <td>{(row.absoluteLift * 100).toFixed(2)}%</td>
      <td>{(row.lowerCI * 100).toFixed(2)}%</td>
      <td>{(row.upperCI * 100).toFixed(2)}%</td>
      <td>{row.pValue}</td>
      <td>{row.isSignificant ? 'TRUE' : 'FALSE'}</td>
      <td></td>
    </>
  ),
}));

export const MeasurementBrandRecallAge = () => {
  return (
    <Card>
      <Grid container spacing={theme.spacing.lg}>
        <Grid item xs={12}>
          <Styled.CardHeader>Brand recall by age</Styled.CardHeader>

          <Styled.CardDescription>
            Which of the following brands of lump charcoal have you heard of?
          </Styled.CardDescription>
        </Grid>

        <Grid item xs={12} md={2}>
          <Styled.ChartHeader>Absolute lift - 18-24</Styled.ChartHeader>
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[0].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Styled.ChartHeader>Absolute lift - 25-34</Styled.ChartHeader>
          {data[1]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[1].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Styled.ChartHeader>Absolute lift - 35-44</Styled.ChartHeader>
          {data[2]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[2].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Styled.ChartHeader>Absolute lift - 45-54</Styled.ChartHeader>
          {data[3]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[3].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Styled.ChartHeader>Absolute lift - 55-64</Styled.ChartHeader>
          {data[4]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[4].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Styled.ChartHeader>Absolute lift - 65+</Styled.ChartHeader>
          {data[5]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[5].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={2}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>1.65%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>0.244</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.errorForeground}
              $color={theme.colors.errorBackground}
            >
              Significant lift: <span>FALSE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={2}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>35.12%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>6.381</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={2}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>-13.31%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>-3.011</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.errorForeground}
              $color={theme.colors.errorBackground}
            >
              Significant lift: <span>FALSE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={2}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>-1.09%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>-0.109</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.errorForeground}
              $color={theme.colors.errorBackground}
            >
              Significant lift: <span>FALSE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={2}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>14.89%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>2.685</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={2}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>-12.12%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>-1.015</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.errorForeground}
              $color={theme.colors.errorBackground}
            >
              Significant lift: <span>FALSE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="brand-recall-age"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
              noPagination
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementBrandRecallAge;
