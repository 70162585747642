import ChartLifeOverTime from './BrandSurveyCharts/ChartLiftOverTime';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: '9/5/24',
    control: 0.4753,
    exposed: 0.5174,
    baselineRecall: 0.4753,
    absoluteLift: 0.0421,
    relativeLift: 0.0886,
    headroomLift: 0.0802,
    upperCI: 0.0657,
    lowerCI: 0.0184,
    pValue: 3.23,
    isSignificant: true,
    charts: [
      {
        id: 'Absolute lift',
        data: [
          {
            x: '9/5/24',
            y: 0.0421,
          },
          {
            x: '9/6/24',
            y: 0.041,
          },
          {
            x: '9/7/24',
            y: 0.1088,
          },
          {
            x: '9/8/24',
            y: 0.1716,
          },
        ],
      },
      {
        id: 'Upper CI',
        data: [
          {
            x: '9/5/24',
            y: 0.0657,
          },
          {
            x: '9/6/24',
            y: 0.0612,
          },
          {
            x: '9/7/24',
            y: 0.1427,
          },
          {
            x: '9/8/24',
            y: 0.2348,
          },
        ],
      },
      {
        id: 'Lower CI',
        data: [
          {
            x: '9/5/24',
            y: 0.0184,
          },
          {
            x: '9/6/24',
            y: 0.0208,
          },
          {
            x: '9/7/24',
            y: 0.0749,
          },
          {
            x: '9/8/24',
            y: 0.1163,
          },
        ],
      },
    ],
  },
  {
    title: '9/6/24',
    control: 0.4723,
    exposed: 0.5133,
    baselineRecall: 0.4723,
    absoluteLift: 0.041,
    relativeLift: 0.0869,
    headroomLift: 0.0777,
    upperCI: 0.0612,
    lowerCI: 0.0208,
    pValue: 3.95,
    isSignificant: true,
  },
  {
    title: '9/7/24',
    control: 0.4533,
    exposed: 0.5621,
    baselineRecall: 0.4533,
    absoluteLift: 0.1088,
    relativeLift: 0.2401,
    headroomLift: 0.199,
    upperCI: 0.1427,
    lowerCI: 0.0749,
    pValue: 6.65,
    isSignificant: true,
  },
  {
    title: '9/8/24',
    control: 0.4328,
    exposed: 0.6044,
    baselineRecall: 0.4328,
    absoluteLift: 0.1716,
    relativeLift: 0.3964,
    headroomLift: 0.3025,
    upperCI: 0.2348,
    lowerCI: 0.1163,
    pValue: 4.32,
    isSignificant: true,
  },
];

const columns = [
  {
    title: 'Date',
    sort: 'date',
  },
  {
    title: 'Control %',
    sort: 'control',
  },
  {
    title: 'Exposed %',
    sort: 'exposed',
  },
  {
    title: 'Baseline recall',
    sort: 'baselineRecall',
  },
  {
    title: 'Absolute lift',
    sort: 'absoluteLift',
  },
  {
    title: 'Relative lift',
    sort: 'relativeLift',
  },
  {
    title: 'Headroom lift',
    sort: 'headroomLift',
  },
  {
    title: 'Upper CI',
    sort: 'upperCI',
  },
  {
    title: 'Lower CI',
    sort: 'lowerCI',
  },
  {
    title: 'P value',
    sort: 'pValue',
  },
  {
    title: 'Is significant',
    sort: 'isSignificant',
  },
];

const rows = data.map((row) => ({
  sortValues: {
    date: row.title,
    control: row.control,
    exposed: row.exposed,
    baselineRecall: row.baselineRecall,
    absoluteLift: row.absoluteLift,
    relativeLift: row.relativeLift,
    headroomLift: row.headroomLift,
    upperCI: row.upperCI,
    lowerCI: row.lowerCI,
    pValue: row.pValue,
    isSignificant: row.isSignificant,
  },
  component: (
    <>
      <td>{row.title}</td>
      <td>{(row.control * 100).toFixed(2)}%</td>
      <td>{(row.exposed * 100).toFixed(2)}%</td>
      <td>{(row.baselineRecall * 100).toFixed(2)}%</td>
      <td>{(row.absoluteLift * 100).toFixed(2)}%</td>
      <td>{(row.relativeLift * 100).toFixed(2)}%</td>
      <td>{(row.headroomLift * 100).toFixed(2)}%</td>
      <td>{(row.upperCI * 100).toFixed(2)}%</td>
      <td>{(row.lowerCI * 100).toFixed(2)}%</td>
      <td>{row.pValue}</td>
      <td>{row.isSignificant ? 'TRUE' : 'FALSE'}</td>
      <td></td>
    </>
  ),
}));

export const MeasurementAdRecallOverTime = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>Ad recall over time</Styled.CardHeader>
        </Grid>

        <Grid item xs={12}>
          {data[0]?.charts && <ChartLifeOverTime charts={data[0].charts} />}
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="ad-recall-over-time"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementAdRecallOverTime;
