import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useCallback } from 'react';

export const useExport = () => {
  const exportDivToPdf = useCallback(
    async (divId, pdfFileName = 'sample.pdf') => {
      const element = document.getElementById(divId);
      console.log(element);
      if (!element) {
        console.error(`Element with id "${divId}" not found.`);
        return;
      }

      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'in', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(pdfFileName);
    },
    []
  );

  const exportDivToImage = useCallback(
    async (divId, imageFileName = 'sample.png') => {
      const element = document.getElementById(divId);
      if (!element) {
        console.error(`Element with id "${divId}" not found.`);
        return;
      }

      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = imgData;
      link.download = imageFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    []
  );

  return { exportDivToPdf, exportDivToImage };
};
