import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import { attainColorPalette } from 'constants/attainColorPalette';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Walmart',
    control: 0.4717,
    exposed: 0.5536,
    absoluteLift: 0.0819,
    relativeLift: 0.1736,
    headroomLift: 0.155,
    controlCI: 0.1128,
    exposedCI: 0.0631,
    pValue: 2.4399,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Walmart',
            value: 0.0819,
            confidenceInterval: [0, 0.0631],
          },
          {
            name: 'Amazon',
            value: 0.0505,
            confidenceInterval: [0, 0.0692],
          },
          {
            name: 'Walgreens',
            value: 0.0859,
            confidenceInterval: [0, 0.078],
          },
          {
            name: 'CVS',
            value: 0.1075,
            confidenceInterval: [0, 0.0839],
          },
          {
            name: 'Target',
            value: 0.0237,
            confidenceInterval: [0, 0.0869],
          },
          {
            name: 'The Home Depot',
            value: 0.075,
            confidenceInterval: [0, 0.1064],
          },
          {
            name: 'Kroger',
            value: 0.0323,
            confidenceInterval: [0, 0.1042],
          },
          {
            name: "Lowe's",
            value: 0.0926,
            confidenceInterval: [0, 0.1991],
          },
          {
            name: 'Costco',
            value: 0.1961,
            confidenceInterval: [0, 0.0631],
          },
        ],
      },
    ],
  },
  {
    title: 'Amazon',
    control: 0.5106,
    exposed: 0.5612,
    absoluteLift: 0.0505,
    relativeLift: 0.0989,
    headroomLift: 0.1032,
    controlCI: 0.1199,
    exposedCI: 0.0692,
    pValue: 1.3843,
    isSignificant: false,
  },
  {
    title: 'Walgreens',
    control: 0.5122,
    exposed: 0.5981,
    absoluteLift: 0.0859,
    relativeLift: 0.1678,
    headroomLift: 0.1762,
    controlCI: 0.1284,
    exposedCI: 0.078,
    pValue: 2.1144,
    isSignificant: true,
  },
  {
    title: 'CVS',
    control: 0.4839,
    exposed: 0.5914,
    absoluteLift: 0.1075,
    relativeLift: 0.2222,
    headroomLift: 0.2083,
    controlCI: 0.1476,
    exposedCI: 0.0839,
    pValue: 2.4149,
    isSignificant: true,
  },
  {
    title: 'Target',
    control: 0.5625,
    exposed: 0.5862,
    absoluteLift: 0.0237,
    relativeLift: 0.0421,
    headroomLift: 0.0542,
    controlCI: 0.1443,
    exposedCI: 0.0869,
    pValue: 0.52394,
    isSignificant: false,
  },
  {
    title: 'The Home Depot',
    control: 0.55,
    exposed: 0.625,
    absoluteLift: 0.075,
    relativeLift: 0.1364,
    headroomLift: 0.1667,
    controlCI: 0.183,
    exposedCI: 0.1064,
    pValue: 1.3376,
    isSignificant: false,
  },
  {
    title: 'Kroger',
    control: 0.5,
    exposed: 0.5323,
    absoluteLift: 0.0323,
    relativeLift: 0.0645,
    headroomLift: 0.0645,
    controlCI: 0.3358,
    exposedCI: 0.1042,
    pValue: 0.5329,
    isSignificant: false,
  },
  {
    title: "Lowe's",
    control: 0.4444,
    exposed: 0.537,
    absoluteLift: 0.0926,
    relativeLift: 0.2083,
    headroomLift: 0.1667,
    controlCI: 0.2725,
    exposedCI: 0.1116,
    pValue: 1.4715,
    isSignificant: false,
  },
  {
    title: 'Costco',
    control: 0.3333,
    exposed: 0.5294,
    absoluteLift: 0.1961,
    relativeLift: 0.5882,
    headroomLift: 0.2941,
    controlCI: 0.3166,
    exposedCI: 0.1991,
    pValue: 1.8825,
    isSignificant: true,
  },
];

const maxValue = data.reduce(
  (max, obj) => (obj.absoluteLift > max ? obj.absoluteLift : max),
  data[0].absoluteLift
);

const columns = [
  {
    title: 'Merchant Buyer',
    sort: 'merchantBuyer',
  },
  {
    title: 'Control %',
    sort: 'control',
  },
  {
    title: 'Exposed %',
    sort: 'exposed',
  },
  {
    title: 'Absolute lift',
    sort: 'absoluteLift',
  },
  {
    title: 'Relative lift',
    sort: 'relativeLift',
  },
  {
    title: 'Headroom lift',
    sort: 'headroomLift',
  },
  {
    title: 'Control CI',
    sort: 'controlCI',
  },
  {
    title: 'Exposed CI',
    sort: 'exposedCI',
  },
  {
    title: 'P value',
    sort: 'pValue',
  },
  {
    title: 'Is significant',
    sort: 'isSignificant',
  },
];

const rows = data?.map((row) => ({
  sortValues: {
    merchantBuyer: row.title,
    control: row.control,
    exposed: row.exposed,
    absoluteLift: row.absoluteLift,
    relativeLift: row.relativeLift,
    headroomLift: row.headroomLift,
    controlCI: row.controlCI,
    exposedCI: row.exposedCI,
    pValue: row.pValue,
    isSignificant: row.isSignificant,
  },
  component: (
    <>
      <td>{row.title}</td>
      <td>{(row.control * 100).toFixed(2)}%</td>
      <td>{(row.exposed * 100).toFixed(2)}%</td>
      <td>{(row.absoluteLift * 100).toFixed(2)}%</td>
      <td>{(row.relativeLift * 100).toFixed(2)}%</td>
      <td>{(row.headroomLift * 100).toFixed(2)}%</td>
      <td>{(row.controlCI * 100).toFixed(2)}%</td>
      <td>{(row.exposedCI * 100).toFixed(2)}%</td>
      <td>{row.pValue}</td>
      <td>{row.isSignificant ? 'TRUE' : 'FALSE'}</td>
      <td></td>
    </>
  ),
}));

export const MeasurementAdRecallByMerchant = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>
            Ad recall by merchant shopping habits
          </Styled.CardHeader>
        </Grid>

        <Grid item xs={12}>
          <Styled.ChartHeader>Absolute lift</Styled.ChartHeader>
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift
              chart={data[0]?.charts?.[0]}
              colors={attainColorPalette}
              type="absolute"
              maxValue={maxValue * 100}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="ad-recall-by-merchant"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
              noPagination
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementAdRecallByMerchant;
