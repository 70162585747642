import ComingSoonBadge from 'components/ComingSoonBadge/ComingSoonBadge';
import DataTable from 'components/DataTable/DataTable';
import Error from 'components/Error/Error';
import MetricBar100 from 'components/MetricBar100/MetricBar100';
import React from 'react';
import useSearchParams from 'hooks/useSearchParams';
import {
  BrandTopRetailer,
  MeasurementInsightsTopRetailerQuery,
  MeasurementInsightsTopRetailerQueryVariables,
  MeasurementOverviewCustomerType,
} from 'types/graphql/generated';
import { Card } from '@klover/attain-design-system';
import { DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID } from 'utils/Measurement/measurementPaths';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementDashboard';
import { findHighestValue } from 'utils/findHighestValue';
import { measurementInsightsTopRetailerQuery } from 'operations/queries/measurementInsightsTopRetailer.query';
import { useQuery } from '@apollo/client';
import * as Styled from './TopRetailers.styles';

export const TopRetailers = () => {
  const [searchParams] = useSearchParams<MeasurementSearchParams>();

  const variables: MeasurementInsightsTopRetailerQueryVariables = React.useMemo(
    () => ({
      input: {
        campaignId: searchParams.campaignId,
        dealIds: searchParams.deals || [],
        attributionWindow:
          searchParams.attributionwindow ??
          DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
        customerType:
          searchParams.customertype ??
          MeasurementOverviewCustomerType.ALL_CUSTOMERS,
        dateRange: {
          startDate: searchParams.datefrom ? Number(searchParams.datefrom) : 0,
          endDate: searchParams.dateto
            ? Number(searchParams.dateto)
            : Math.floor(Date.now() / 1000),
        },
      },
    }),
    [searchParams]
  );

  const { data, loading, error } = useQuery<
    MeasurementInsightsTopRetailerQuery,
    MeasurementInsightsTopRetailerQueryVariables
  >(measurementInsightsTopRetailerQuery, { variables });

  const rows = data?.measurementInsightsTopRetailer.topRetailers || [];
  const highestPercentSpend = findHighestValue(rows, 'percentSpend');
  const highestPercentTransactions = findHighestValue(
    rows,
    'percentTransactions'
  );

  const columns = React.useMemo(
    () => [
      { title: 'Retailer', sort: 'title', width: '25%' },
      { title: '% of spend', sort: 'percentSpendValue', width: '25%' },
      { title: '% of trips', sort: 'percentTransactionsValue', width: '25%' },
      {
        title: (
          <>
            ROAS
            <ComingSoonBadge />
          </>
        ),
        sort: 'roasValue',
        width: '25%',
      },
    ],
    []
  );

  const formattedRows = rows?.map((row: BrandTopRetailer) => ({
    sortValues: {
      title: row.merchant.title,
      percentSpendValue: row.percentSpend.value,
      percentTransactionsValue: row.percentTransactions.value,
    },
    component: (
      <React.Fragment key={row.merchant.id}>
        <td>
          <Styled.AlignCenter>
            {row.merchant.logo && (
              <Styled.Logo src={row.merchant.logo} alt="" />
            )}
            <div>
              <Styled.Segment>{row.merchant.title}</Styled.Segment>
            </div>
          </Styled.AlignCenter>
        </td>
        <td>
          <MetricBar100
            index={row.percentSpend}
            highestIndex={highestPercentSpend}
          />
        </td>
        <td>
          <MetricBar100
            index={row.percentTransactions}
            highestIndex={highestPercentTransactions}
          />
        </td>
        <td>--</td>
      </React.Fragment>
    ),
  }));

  if (error) {
    return (
      <Card>
        <Error message="Error loading top retailers" />
      </Card>
    );
  }

  return (
    <>
      {rows.length > 0 && (
        <DataTable
          id="top-retailers"
          title="Top retailers"
          description="Top retailers"
          columns={columns}
          rows={formattedRows}
          loading={loading}
        />
      )}
    </>
  );
};

export default TopRetailers;
