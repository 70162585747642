import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Kingsford',
    control: 998,
    exposed: 2801,
    baselineRecall: 0.696,
    absoluteLift: 0.0613,
    pValue: 10.01,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.696,
            confidenceInterval: [0, 0.0497],
          },
          {
            name: 'Exposed',
            value: 0.7573,
            confidenceInterval: [0, 0.0729],
          },
        ],
      },
      {
        type: 'relative',
        values: [
          {
            name: 'Control',
            value: 0.696,
          },
          {
            name: 'Exposed',
            value: 0.7573,
          },
        ],
      },
      {
        type: 'headroom',
        values: [
          {
            name: 'Control',
            value: 0.696,
          },
          {
            name: 'Exposed',
            value: 0.7573,
          },
        ],
      },
    ],
  },
  {
    title: 'Royal Oak',
    control: 371,
    exposed: 1157,
    baselineRecall: 0.2587,
  },
  {
    title: 'None of these',
    control: 285,
    exposed: 537,
    baselineRecall: 0.1987,
  },
  {
    title: 'Cowboy',
    control: 68,
    exposed: 187,
    baselineRecall: 0.0474,
  },
  {
    title: 'Open Nature',
    control: 77,
    exposed: 134,
    baselineRecall: 0.0537,
  },
];

const columns = [
  {
    title: 'Answer',
    sort: 'answer',
  },
  {
    title: '# Control',
    sort: 'control',
  },
  {
    title: '# Exposed',
    sort: 'exposed',
  },
  {
    title: 'Baseline recall',
    sort: 'baselineRecall',
  },
];

const rows = data?.map((row) => ({
  sortValues: {
    answer: row.title,
    control: row.control,
    exposed: row.exposed,
    baselineRecall: row.baselineRecall,
  },
  component: (
    <>
      <td>{row.title}</td>
      <td>{row.control}</td>
      <td>{row.exposed}</td>
      <td>{(row.baselineRecall * 100).toFixed(2)}%</td>
      <td></td>
    </>
  ),
}));

export const MeasurementBrandRecall = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>Brand recall</Styled.CardHeader>

          <Styled.CardDescription>
            Which of the following brands of lump charcoal have you heard of?
          </Styled.CardDescription>
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Absolute lift</Styled.ChartHeader>{' '}
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[0]?.charts?.[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Relative lift</Styled.ChartHeader>
          {data[0]?.charts?.[1] && (
            <ChartAbsoluteLift chart={data[0]?.charts?.[1]} type="relative" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Headroom lift</Styled.ChartHeader>{' '}
          {data[0]?.charts?.[2] && (
            <ChartAbsoluteLift chart={data[0]?.charts?.[2]} type="headroom" />
          )}
        </Grid>

        <Grid item xs={12}>
          <Styled.Badges>
            <Styled.Badge>
              Absolute lift: <span>6.13%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>10.01</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="ad-recall"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementBrandRecall;
