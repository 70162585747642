import CampaignTrends from '../CampaignTrends/CampaignTrends';
import CompetitorConversions from './CompetitorConversions/CompetitorConversions';
import CustomerLifetimeValue from './CustomerLifetimeValue/CustomerLifetimeValue';
import GeoLocation from './GeoLocation/GeoLocation';
import MeasurementGhostLoader from 'views/Measurement/MeasurementGhostLoader';
import OptimalFrequency from './OptimalFrequency/OptimalFrequency';
import PaymentChannels from './PaymentChannels/PaymentChannels';
import React from 'react';
import StatCard from 'components/StatCard/StatCard';
import TimeToConversion from './TimeToConversion/TimeToConversion';
import TopRetailers from './TopRetailers/TopRetailers';
import measurementConversionsQuery from 'operations/queries/measurementConversions.query';
import measurementWeekOverWeekQuery from 'operations/queries/measurementWeekOverWeek.query';
import useMe from 'hooks/useMe/useMe';
import useSearchParams from 'hooks/useSearchParams';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { AudienceInsights } from './AudienceInsights/AudienceInsights';
import { CardTypes, Grid } from '@klover/attain-design-system';
import {
  MeasurementConversionsQuery,
  MeasurementConversionsQueryVariables,
  MeasurementOverviewCustomerType,
  MeasurementWeekOverWeekQuery,
  MeasurementWeekOverWeekQueryVariables,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementInsightsDashboard';
import {
  getMeasurementConversionsInputFromSearchParams,
  getMeasurementWeekOverWeekInputFromSearchParams,
} from 'utils/Measurement/measurementInputFromUrlParams';
import { useLazyQuery } from '@apollo/client';
import * as Styled from '../Measurement.styles';

export const MeasurementCampaignInsights = () => {
  const me = useMe();
  const [searchParams] = useSearchParams<MeasurementSearchParams>();

  React.useEffect(() => {
    window.analytics.page('Page view: Measurement > Campaign insights', {
      ...me,
    });
  }, []);

  const [
    getConversionsData,
    { data: conversionsData, loading: loadingConversionsData },
  ] = useLazyQuery<
    MeasurementConversionsQuery,
    MeasurementConversionsQueryVariables
  >(measurementConversionsQuery);

  const [getWowData, { data: wowData }] = useLazyQuery<
    MeasurementWeekOverWeekQuery,
    MeasurementWeekOverWeekQueryVariables
  >(measurementWeekOverWeekQuery);

  React.useEffect(() => {
    const wowInput = getMeasurementWeekOverWeekInputFromSearchParams(
      searchParams.campaignId,
      searchParams
    );
    const conversionsInput = getMeasurementConversionsInputFromSearchParams(
      searchParams.campaignId,
      searchParams
    );

    if (wowInput) {
      getWowData({ variables: { input: wowInput } });
    }
    if (conversionsInput) {
      getConversionsData({
        variables: { input: conversionsInput },
      });
    }
  }, [searchParams]);

  const conversionMetrics =
    conversionsData?.measurementConversions.__typename ===
    'MeasurementConversionsSuccess'
      ? conversionsData.measurementConversions.metrics
      : null;

  const wowMetrics =
    wowData?.measurementWeekOverWeek.__typename ===
    'MeasurementWeekOverWeekSuccess'
      ? wowData.measurementWeekOverWeek.metrics
      : null;

  const uiOptimalFrequencyInsights = useUiFlag('ui_optimal_frequency_insights');
  const uiTimeToConversionInsights = useUiFlag(
    'ui_time_to_conversion_insights'
  );
  const uiCustomerLifetimeValueInsights = useUiFlag(
    'ui_customer_lifetime_value_insights'
  );
  const uiTopRetailers = useUiFlag('ui_top_retailers_insights');
  const uiCompetitorConversions = useUiFlag('ui_competitor_conversions');

  const showOptimalFrequency =
    uiOptimalFrequencyInsights.isReady && uiOptimalFrequencyInsights.enabled;
  const showTimeToConversion =
    uiTimeToConversionInsights.isReady && uiTimeToConversionInsights.enabled;

  const showCompetitorConversions =
    uiCompetitorConversions.isReady && uiCompetitorConversions.enabled;
  const showCLTV =
    uiCustomerLifetimeValueInsights.isReady &&
    uiCustomerLifetimeValueInsights.enabled;
  const showTopRetailers = uiTopRetailers.isReady && uiTopRetailers.enabled;

  const isLoading = loadingConversionsData;
  return !isLoading ? (
    <>
      <Grid item xs={12} sm={6}>
        <StatCard
          title={'Avg txn amount (total)'}
          detail={conversionMetrics?.avgTotalSpend?.title ?? 'N/A'}
          type={CardTypes.Inverted}
        >
          {wowMetrics?.avgTotalSpend && (
            <Styled.StatCardBodyMetric $inverted>
              <span>{wowMetrics.avgTotalSpend.title}</span> week over week
            </Styled.StatCardBodyMetric>
          )}
        </StatCard>
      </Grid>

      <Grid item xs={12} sm={3}>
        <StatCard
          title={'Avg txn amount (per txn)'}
          detail={conversionMetrics?.avgTransactionAmount?.title ?? 'N/A'}
        >
          {wowMetrics?.avgTransactionAmount && (
            <Styled.StatCardBodyMetric>
              <span>{wowMetrics.avgTransactionAmount.title}</span> week over
              week
            </Styled.StatCardBodyMetric>
          )}
        </StatCard>
      </Grid>

      <Grid item xs={12} sm={3}>
        <StatCard
          title={'Cost per transaction'}
          detail={conversionMetrics?.costPerTransaction?.title ?? 'N/A'}
        >
          {wowMetrics?.costPerTransaction && (
            <Styled.StatCardBodyMetric>
              <span>{wowMetrics.costPerTransaction.title}</span> week over week
            </Styled.StatCardBodyMetric>
          )}
        </StatCard>
      </Grid>

      <Grid item xs={12} sm={6}>
        <StatCard
          title={'Avg converter purchase frequency'}
          detail={conversionMetrics?.avgTransactionFrequency?.title ?? 'N/A'}
        >
          {wowMetrics?.avgTransactionFrequency && (
            <Styled.StatCardBodyMetric>
              <span>{wowMetrics.avgTransactionFrequency?.title}</span> week over
              week
            </Styled.StatCardBodyMetric>
          )}
        </StatCard>
      </Grid>

      <Grid item xs={12} sm={6}>
        <StatCard
          title={'Avg Converter impression frequency'}
          detail={conversionMetrics?.avgImpressionFrequency?.title ?? 'N/A'}
        >
          {wowMetrics?.avgImpressionFrequency && (
            <Styled.StatCardBodyMetric>
              <span>{wowMetrics.avgImpressionFrequency.title}</span> week over
              week
            </Styled.StatCardBodyMetric>
          )}
        </StatCard>
      </Grid>

      {showOptimalFrequency && (
        <Grid item xs={6}>
          <OptimalFrequency
            campaignId={searchParams.campaignId}
            attributionWindow={searchParams.attributionwindow}
            startDate={
              searchParams.datefrom ? Number(searchParams.datefrom) : 0
            }
            endDate={
              searchParams.dateto
                ? Number(searchParams.dateto)
                : Math.floor(Date.now() / 1000)
            }
            dealIds={searchParams.deals ?? []}
            newCustomerWindow={
              searchParams.customertype ===
              MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS
                ? searchParams.newcustomerwindow
                : undefined
            }
          />
        </Grid>
      )}

      {showTimeToConversion && (
        <Grid item xs={6}>
          <TimeToConversion
            campaignId={searchParams.campaignId}
            newCustomersWindow={searchParams.newcustomerwindow}
            dealIds={searchParams.deals ?? []}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <CampaignTrends />
      </Grid>

      <Grid item xs={12}>
        <AudienceInsights />
      </Grid>

      {showTopRetailers && (
        <Grid item xs={12}>
          <TopRetailers />
        </Grid>
      )}

      <Grid item xs={12}>
        <PaymentChannels />
      </Grid>

      <Grid item xs={12}>
        <GeoLocation />
      </Grid>

      {showCompetitorConversions && (
        <Grid item xs={12}>
          <CompetitorConversions
            campaignId={searchParams.campaignId}
            attributionWindow={searchParams.attributionwindow}
            newCustomersWindow={searchParams.newcustomerwindow}
            dealIds={searchParams.deals}
            startDate={
              searchParams.datefrom ? Number(searchParams.datefrom) : 0
            }
            endDate={
              searchParams.dateto
                ? Number(searchParams.dateto)
                : Math.floor(Date.now() / 1000)
            }
          />
        </Grid>
      )}

      {showCLTV && (
        <Grid item xs={12}>
          <CustomerLifetimeValue
            campaignId={searchParams.campaignId}
            attributionWindow={searchParams.attributionwindow}
            newCustomersWindow={searchParams.newcustomerwindow}
            dealIds={searchParams.deals}
            startDate={
              searchParams.datefrom ? Number(searchParams.datefrom) : 0
            }
            endDate={
              searchParams.dateto
                ? Number(searchParams.dateto)
                : Math.floor(Date.now() / 1000)
            }
          />
        </Grid>
      )}
    </>
  ) : (
    <MeasurementGhostLoader />
  );
};

export default MeasurementCampaignInsights;
