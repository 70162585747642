import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Female',
    control: 48,
    exposed: 114,
    baselineRecall: 0.4948,
    absoluteLift: 0.021,
    lowerCI: -0.0343,
    upperCI: 0.0766,
    pValue: 0.749,
    isSignificant: false,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.4948,
            confidenceInterval: [0, 0.1109],
          },
          {
            name: 'Exposed',
            value: 0.5158,
            confidenceInterval: [0, 0.1109],
          },
        ],
      },
    ],
  },
  {
    title: 'Male',
    control: 19,
    exposed: 65,
    baselineRecall: 0.5135,
    absoluteLift: 0.077,
    lowerCI: 0.0334,
    upperCI: 0.1896,
    pValue: 2.698,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.5135,
            confidenceInterval: [0, 0.1562],
          },
          {
            name: 'Exposed',
            value: 0.5905,
            confidenceInterval: [0, 0.1562],
          },
        ],
      },
    ],
  },
];

const columns = [
  {
    title: 'Gender',
    sort: 'gender',
  },
  {
    title: '# Control',
    sort: 'control',
  },
  {
    title: '# Exposed',
    sort: 'exposed',
  },
  {
    title: 'Baseline recall',
    sort: 'baselineRecall',
  },
  {
    title: 'Absolute lift',
    sort: 'absoluteLift',
  },
  {
    title: 'Lower CI',
    sort: 'lowerCI',
  },
  {
    title: 'Upper CI',
    sort: 'upperCI',
  },
  {
    title: 'P value',
    sort: 'pValue',
  },
  {
    title: 'Is significant',
    sort: 'isSignificant',
  },
];

const rows = data.map((row) => ({
  sortValues: {
    gender: row.title,
    control: row.control,
    exposed: row.exposed,
    baselineRecall: row.baselineRecall,
    absoluteLift: row.absoluteLift,
    lowerCI: row.lowerCI,
    upperCI: row.upperCI,
    pValue: row.pValue,
    isSignificant: row.isSignificant,
  },
  component: (
    <>
      <td>{row.title}</td>
      <td>{row.control}</td>
      <td>{row.exposed}</td>
      <td>{(row.baselineRecall * 100).toFixed(2)}%</td>
      <td>{(row.absoluteLift * 100).toFixed(2)}%</td>
      <td>{(row.lowerCI * 100).toFixed(2)}%</td>
      <td>{(row.upperCI * 100).toFixed(2)}%</td>
      <td>{row.pValue}</td>
      <td>{row.isSignificant ? 'TRUE' : 'FALSE'}</td>
      <td></td>
    </>
  ),
}));

export const MeasurementBrandRecallGender = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>Band recall by gender</Styled.CardHeader>

          <Styled.CardDescription>
            Which of the following brands of lump charcoal have you heard of?
          </Styled.CardDescription>
        </Grid>

        <Grid item xs={12} md={6}>
          <Styled.ChartHeader>Absolute lift - Female</Styled.ChartHeader>
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[0].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Styled.ChartHeader>Absolute lift - Male</Styled.ChartHeader>
          {data[1]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[1].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={6}>
          <Styled.Badges>
            <Styled.Badge>
              Absolute lift: <span>2.10%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>0.749</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.errorForeground}
              $color={theme.colors.errorBackground}
            >
              Significant lift: <span>FALSE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={6}>
          <Styled.Badges>
            <Styled.Badge>
              Absolute lift: <span>11.15%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>2.698</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="brand-recall-gender"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementBrandRecallGender;
