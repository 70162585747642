import DataTable from 'components/DataTable/DataTable';
import Error from 'components/Error/Error';
import MetricBar100 from 'components/MetricBar100/MetricBar100';
import React from 'react';
import Regions from './Regions';
import useSearchParams from 'hooks/useSearchParams';
import {
  BrandDivisionBreakdown,
  MeasurementInsightsGeoDataQuery,
  MeasurementInsightsGeoDataQueryVariables,
  MeasurementOverviewCustomerType,
} from 'types/graphql/generated';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  theme,
} from '@klover/attain-design-system';
import { DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID } from 'utils/Measurement/measurementPaths';
import { Globe, List } from '@phosphor-icons/react';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementDashboard';
import { measurementInsightsGeoDataQuery } from 'operations/queries/measurementInsightsGeoData.query';
import { useQuery } from '@apollo/client';
import * as Styled from './GeoLocation.styles';

const GeoLocation = () => {
  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const [showMap, setShowMap] = React.useState(true);

  const variables: MeasurementInsightsGeoDataQueryVariables = React.useMemo(
    () => ({
      input: {
        campaignId: searchParams.campaignId,
        dealIds: searchParams.deals || [],
        attributionWindow:
          searchParams.attributionwindow ??
          DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
        customerType:
          searchParams.customertype ??
          MeasurementOverviewCustomerType.ALL_CUSTOMERS,
        newCustomersWindow:
          searchParams.customertype ===
          MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS
            ? searchParams.newcustomerwindow
            : undefined,
        dateRange: {
          startDate: searchParams.datefrom ? Number(searchParams.datefrom) : 0,
          endDate: searchParams.dateto
            ? Number(searchParams.dateto)
            : Math.floor(Date.now() / 1000),
        },
      },
    }),
    [searchParams]
  );

  const { data, loading, error } = useQuery<
    MeasurementInsightsGeoDataQuery,
    MeasurementInsightsGeoDataQueryVariables
  >(measurementInsightsGeoDataQuery, { variables });

  const { measurementInsightsGeoData } = data || {};
  const divisions = measurementInsightsGeoData?.divisions || [];
  const dmas = measurementInsightsGeoData?.dmas || [];

  const columns = React.useMemo(
    () => [
      { title: 'Census region', sort: 'region', width: '25%' },
      { title: '% of impressions', sort: 'impressions', width: '75%' },
    ],
    []
  );

  if (error)
    return (
      <Card>
        <Grid container spacing={theme.spacing.lg}>
          <Grid item xs={12}>
            <Styled.CardTitle>Conversions by location</Styled.CardTitle>
          </Grid>

          <Grid item xs={12}>
            <Error message="Error loading data" />
          </Grid>
        </Grid>
      </Card>
    );

  const formatData = (rows: BrandDivisionBreakdown[]) =>
    rows.map((row, i) => ({
      sortValues: { region: row.division, impressions: row.percent.value },
      component: (
        <React.Fragment key={i}>
          <td>
            <Styled.AlignCenter>
              <Styled.Circle $name={row.division} />
              <span>{row.division}</span>
            </Styled.AlignCenter>
          </td>
          <td>
            <MetricBar100 index={row.percent} fullWidth />
          </td>
        </React.Fragment>
      ),
    }));

  const buttons = (
    <>
      {!loading && (
        <ButtonGroup>
          <Button
            variant={showMap ? 'contained' : 'outlined'}
            onClick={() => setShowMap(true)}
          >
            <Globe size={theme.icons.lg} />
          </Button>
          <Button
            variant={!showMap ? 'contained' : 'outlined'}
            onClick={() => setShowMap(false)}
          >
            <List size={theme.icons.lg} />
          </Button>
        </ButtonGroup>
      )}

      {/* <ButtonGroup>
        <Button
          variant={showDivisions ? 'contained' : 'outlined'}
          onClick={() => setShowDivisions(true)}
        >
          Census division
        </Button>
        <Button
          variant={!showDivisions ? 'contained' : 'outlined'}
          onClick={() => setShowDivisions(false)}
        >
          States & DMAs
        </Button>
      </ButtonGroup> */}
    </>
  );

  const rows = true ? formatData(divisions) : formatData(dmas);

  return (
    <>
      {showMap ? (
        <Styled.Wrapper>
          <Styled.CardHeader>
            <Styled.CardTitle>Conversions by location</Styled.CardTitle>
            {buttons}
          </Styled.CardHeader>

          <Regions loading={loading} divisions={true ? divisions : dmas} />
        </Styled.Wrapper>
      ) : (
        <DataTable
          id="conversions-by-location"
          title="Conversions by location"
          description="Here is a breakdown of conversions based on location (U.S. Census Regions)"
          customActions={buttons}
          columns={columns}
          rows={rows}
          loading={false}
        />
      )}
    </>
  );
};

export default GeoLocation;
