import { Datum } from '@nivo/line';
import {
  IncrementalityDailyLift,
  LiftSignificance,
  MeasurementIncrementalitySuccess,
  MeasurementOverviewSuccess,
  MeasurementTrendedMetrics,
} from 'types/graphql/generated';
import { IncrementalityDataChart } from 'views/Measurement/MeasurementDashboard';
import { TrendsDataChart } from 'views/Measurement/MeasurementInsightsDashboard';
import { format } from 'date-fns';
import { significanceMap } from 'components/Measurement/MeasurementIncrementality/MeasurementIncrementality';
import { theme } from '@klover/attain-design-system';

const formatDate = (date: string | null | undefined) => {
  if (!date) {
    return null;
  }
  const splitDate = date.split('-');
  const formattedDate = format(
    new Date(
      Number(splitDate[0]),
      Number(splitDate[1]) - 1,
      Number(splitDate[2])
    ),
    'MMM dd yyyy'
  );
  return formattedDate;
};

export const getDataChartImpressions = (
  result: MeasurementOverviewSuccess | MeasurementIncrementalitySuccess | null
) => {
  return [
    {
      id: 'Running total impressions',
      color: theme.colors.CHART_PRIMARY,
      startDate: formatDate(result?.impressions?.time[0]),
      midDate: formatDate(
        result?.impressions?.time[
          Math.round(result?.impressions.time.length / 2)
        ]
      ),
      endDate: formatDate(
        result?.impressions?.time[result?.impressions.time.length - 1]
      ),
      data: result?.impressions?.cumulative?.map((item, i) => ({
        bar: i,
        value: result.impressions.daily[i],
        x: formatDate(result.impressions.time[i]) ?? '',
        y: item,
      })),
    },
  ];
};

export const getDataChartReach = (
  result: MeasurementOverviewSuccess | MeasurementIncrementalitySuccess | null
) => {
  return [
    {
      id: 'Running total reach',
      color: theme.colors.CHART_PRIMARY,
      startDate: formatDate(result?.reach?.time[0]),
      midDate: formatDate(
        result?.reach?.time[Math.round(result?.reach?.time.length / 2)]
      ),
      endDate: formatDate(result?.reach?.time[result?.reach?.time.length - 1]),
      data: result?.reach?.cumulative?.map((item, i) => ({
        bar: i,
        value: result.reach.daily[i],
        x: formatDate(result.reach.time[i]) ?? '',
        y: item,
      })),
    },
  ];
};

export const getDataChartIncrementalityCi = (
  dailyLifts?: IncrementalityDailyLift[] | null,
  showFullChart?: boolean
): [IncrementalityDataChart, IncrementalityDataChart] => {
  // protects against array of size 1 where we would try to index into position 1 and get undefined.
  const midDateIdx =
    dailyLifts && dailyLifts.length > 1 ? Math.round(dailyLifts.length / 2) : 0;

  const startDate = dailyLifts ? dailyLifts[0]?.date : null;
  const midDate = dailyLifts ? dailyLifts[midDateIdx].date : null;
  const endDate = dailyLifts ? dailyLifts[dailyLifts.length - 1].date : null;

  const TwoWeekDate = new Date(startDate ?? '');
  TwoWeekDate.setDate(TwoWeekDate.getDate() + 14);
  let obfucateTo = '';
  const upperBoundData: Datum[] | null = dailyLifts
    ? dailyLifts.map((dailyLift) => {
        const obfuscateY = showFullChart
          ? false
          : new Date(dailyLift.date) < TwoWeekDate;
        if (
          !showFullChart &&
          (obfuscateY || new Date(dailyLift.date) <= TwoWeekDate)
        ) {
          obfucateTo = dailyLift.date;
        }
        return {
          isSig:
            significanceMap[dailyLift.lift.significance] !==
            LiftSignificance.NOT_SIGNIFICANT,
          value: dailyLift.liftUpperBound.title,
          x: formatDate(dailyLift.date),
          y: !obfuscateY ? dailyLift.liftUpperBound.value * 100 : null,
        };
      })
    : null;

  const lowerBoundData: Datum[] | null = dailyLifts
    ? dailyLifts.map((dailyLift) => {
        const obfuscateY = showFullChart
          ? false
          : new Date(dailyLift.date) < TwoWeekDate;
        if (
          !showFullChart &&
          (obfuscateY || new Date(dailyLift.date) <= TwoWeekDate)
        ) {
          obfucateTo = dailyLift.date;
        }
        return {
          isSig:
            significanceMap[dailyLift.lift.significance] !==
            LiftSignificance.NOT_SIGNIFICANT,
          value: dailyLift.liftLowerBound.title,
          x: formatDate(dailyLift.date),
          y: !obfuscateY ? dailyLift.liftLowerBound.value * 100 : null,
        };
      })
    : null;

  return [
    {
      id: 'upper bound',
      color: theme.colors.CHART_11,
      startDate: formatDate(startDate),
      midDate: formatDate(midDate),
      endDate: formatDate(endDate),
      data: upperBoundData,
      obfuscateToData: formatDate(obfucateTo) || '',
    },
    {
      id: 'lower bound',
      color: theme.colors.CHART_11,
      startDate: formatDate(startDate),
      midDate: formatDate(midDate),
      endDate: formatDate(endDate),
      data: lowerBoundData,
      obfuscateToData: formatDate(obfucateTo) || '',
    },
  ];
};

export const getDataChartIncrementality = (
  dailyLifts?: IncrementalityDailyLift[] | null,
  showFullChart?: boolean
): IncrementalityDataChart => {
  // protects against array of size 1 where we would try to index into position 1 and get undefined.
  const midDateIdx =
    dailyLifts && dailyLifts.length > 1 ? Math.round(dailyLifts.length / 2) : 0;

  const startDate = dailyLifts ? dailyLifts[0]?.date : null;
  const midDate = dailyLifts ? dailyLifts[midDateIdx].date : null;
  const endDate = dailyLifts ? dailyLifts[dailyLifts.length - 1].date : null;

  const TwoWeekDate = new Date(startDate ?? '');
  TwoWeekDate.setDate(TwoWeekDate.getDate() + 14);

  let obfucateTo = '';

  const lineData: Datum[] | null = dailyLifts
    ? dailyLifts.map((dailyLift) => {
        const obfuscateY = showFullChart
          ? false
          : new Date(dailyLift.date) < TwoWeekDate;
        if (
          !showFullChart &&
          (obfuscateY || new Date(dailyLift.date) <= TwoWeekDate)
        ) {
          obfucateTo = dailyLift.date;
        }
        const ci =
          dailyLift.liftUpperBound.value - dailyLift.liftLowerBound.value;
        return {
          isSig:
            significanceMap[dailyLift.lift.significance] !==
            LiftSignificance.NOT_SIGNIFICANT,
          ci: (ci * 100) / 2,
          value: dailyLift.lift.value?.value,
          x: formatDate(dailyLift.date),
          y: !obfuscateY ? (dailyLift.lift.value?.value || 0) * 100 : null,
        };
      })
    : null;

  return {
    id: 'Running total incrementality',
    color: theme.colors.CHART_PRIMARY,
    startDate: formatDate(startDate),
    midDate: formatDate(midDate),
    endDate: formatDate(endDate),
    data: lineData,
    obfuscateToData: formatDate(obfucateTo) || '',
  };
};

export const getDataChartTrends = (
  trendedMetric: MeasurementTrendedMetrics | undefined
): TrendsDataChart => {
  const trendName = trendedMetric?.metricName;
  const trend = trendedMetric?.timeSeries;
  const dataType = trend?.at(0)?.dataPoint.__typename;
  const startDate =
    trend && trend[0]?.time
      ? format(new Date(trend[0].time * 1000), 'MMM dd yyyy')
      : null;
  const midDate =
    trend && trend[Math.round(trend.length / 2)]?.time
      ? format(trend[Math.round(trend.length / 2)].time * 1000, 'MMM dd yyyy')
      : null;
  const endDate =
    trend && trend[trend.length - 1]?.time
      ? format(trend[trend.length - 1].time * 1000, 'MMM dd yyyy')
      : null;
  let sign = '';
  let multiplier: number | null = null;

  switch (dataType) {
    case 'PercentageValue':
      multiplier = 100;
      sign = '%';
      break;
    case 'MoneyValue':
      sign = '$';
      break;
  }

  const lineData: Datum[] | null = trend
    ? trend.map((point) => {
        return {
          value: point.dataPoint.title,
          x: format(new Date(point.time * 1000), 'MMM dd yyyy'),
          y: multiplier
            ? point.dataPoint.value * multiplier
            : point.dataPoint.value,
        };
      })
    : null;

  return {
    id: trendName ?? 'unknown trend name',
    color: theme.colors.CHART_PRIMARY,
    sign: sign,
    startDate: startDate,
    midDate: midDate,
    endDate: endDate,
    data: lineData,
  };
};
